import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Bike as BikeIcon,
  BikeElectric as BikeElectricIcon,
  Scooter as ScooterIcon,
  BikeChildSeat as BikeChildSeatIcon
} from '@urbaninfrastructure/react-icons';
import { Text, Flex } from '../../';
import messages from '../messages';
import {
  VehicleCategory2,
  DockGroups_dockGroups_availabilityInfo_availableVehicleCategories
} from '../../../core-types';

const getCategoryIconAndMessage = (
  category: VehicleCategory2
):
  | {
      Icon: any;
      message: {
        id: string;
        defaultMessage: string;
      };
    }
  | undefined => {
  switch (category) {
    case VehicleCategory2.bike: {
      return {
        Icon: BikeIcon,
        message: messages.bikesAvailability
      };
    }
    case VehicleCategory2.ebike: {
      return {
        Icon: BikeElectricIcon,
        message: messages.eBikesAvailability
      };
    }
    case VehicleCategory2.ebike_with_childseat: {
      return {
        Icon: BikeChildSeatIcon,
        message: messages.eBikesWithChildSeatAvailability
      };
    }
    case VehicleCategory2.scooter: {
      return {
        Icon: ScooterIcon,
        message: messages.scootersAvailability
      };
    }
  }
};

export interface AvailableVehicleCategoryProps {
  vehicleCategory: DockGroups_dockGroups_availabilityInfo_availableVehicleCategories;
}

export const AvailableVehicleCategory: FC<AvailableVehicleCategoryProps> = props => {
  const { vehicleCategory } = props;

  const result = useMemo(
    () => getCategoryIconAndMessage(vehicleCategory.category),
    [vehicleCategory.category]
  );
  if (!result) {
    return null;
  }

  const { Icon, message } = result;
  const { count } = vehicleCategory;

  return (
    <Flex alignItems="center" mb="xxs">
      <Icon size="32px" color="primary" />
      <Text fontSize={1} ml="xxs" whiteSpace="pre">
        {count}{' '}
        <FormattedMessage
          {...message}
          values={{ count, plural_scooter: count }}
        />
      </Text>
    </Flex>
  );
};
