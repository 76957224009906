import cookie, { CookieSerializeOptions, CookieParseOptions } from 'cookie';
import { ServerResponse, IncomingMessage } from 'http';

const serialize = (
  name: string,
  value: string,
  options: CookieSerializeOptions = {}
): string => {
  return cookie.serialize(name, value, options);
};

const setServerCookie = (
  res: ServerResponse,
  name: string,
  value: any,
  options: CookieSerializeOptions = {}
) => {
  const stringValue =
    typeof value === 'object' ? 'j:' + JSON.stringify(value) : String(value);

  if (options.maxAge !== undefined) {
    options.expires = new Date(Date.now() + options.maxAge);
    options.maxAge /= 1000;
  }

  res.setHeader('Set-Cookie', serialize(name, String(stringValue), options));
};

function clearCookie(res: ServerResponse, name: string) {
  setServerCookie(res, name, null, { maxAge: -1 });
}

// clear cookie server-side or in the client
function clear(res: ServerResponse | null, name: string): void {
  if (res) {
    clearCookie(res, name);
  } else if (typeof window !== 'undefined') {
    document.cookie = serialize(name, '', {
      maxAge: -1,
      path: '/'
    });
  }
}

// parse cookie server-side or in the client
export function parse(
  req?: IncomingMessage,
  options: CookieParseOptions = {}
): Record<string, any> {
  let c;
  if (req) {
    c = req.headers.cookie;
  } else if (typeof window !== 'undefined') {
    if (document && document.cookie) {
      c = document.cookie;
    }
  }
  return cookie.parse(c || '', options);
}

export default { clear, parse, serialize };
