import { gql } from '@apollo/client';
import { CURRENT_SUBSCRIPTION_FRAGMENT } from './currentSubscription';

export const CURRENT_USER_PRODUCT_RENEWAL_FRAGMENT = gql`
  fragment CurrentUserProductRenewal on ProductRenewal {
    ... on ProductRenewalPending {
      product {
        id
      }
    }
    ... on ProductRenewalScheduled {
      product {
        id
        price
      }
      scheduledAt
    }
    ... on ProductRenewalFailed {
      product {
        id
        name
      }
    }
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  ${CURRENT_SUBSCRIPTION_FRAGMENT}
  ${CURRENT_USER_PRODUCT_RENEWAL_FRAGMENT}
  fragment CurrentUser on User {
    id
    name
    email
    gender
    birthYear
    preferredLanguageCode
    postalCode
    havePinCode
    currentSubscription {
      ...CurrentSubscription
    }
    phoneNumber
    parsedPhoneNumber {
      countryCode
      nationalNumber
      formattedNumber
    }
    penaltyStatus
    agreedToReceiveEmail
    agreedToTerms
    agreedToReceiveExternalPromotions
    agreedToRegistrationAgreementWithdrawal
    agreedToSubscriptionAgreementWithdrawal
    emailConfirmed
    agreedToSendDataToThirdParties
    paymentMethod {
      __typename
      ... on Card {
        id
        expiresAt
        expired
        last4
        brand
        displayBrand
        removable
      }
    }
    requiresAddingPaymentCard
    hasUnpaidOrders
    productRenewals {
      ...CurrentUserProductRenewal
    }
    superpowers
    rfid
    wallet {
      enabled
      balance
      currency
    }
    currentTrip {
      id
    }
  }
`;
