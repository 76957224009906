import * as React from 'react';
import FormattedDuration from 'react-intl-formatted-duration';
import { FormattedMessage } from 'react-intl';

import { ConnectionPriceMatrix_priceMatrix_matrix } from '../../core-types';
import { FormattedCurrency } from '../FormattedCurrency';
import { messages } from './messages';

type MatrixPeriod = Omit<
  ConnectionPriceMatrix_priceMatrix_matrix,
  '__typename'
>;

export const MatrixPeriodPrice: React.FC<{ period: MatrixPeriod }> = ({
  period
}) => {
  if (period.price === 0) {
    return <FormattedMessage {...messages.included} />;
  }

  return (
    <>
      <FormattedCurrency value={period.price} /> /{' '}
      <FormattedDuration seconds={period.minutes * 60} />
    </>
  );
};
