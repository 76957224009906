import { FC } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { Link as RouteLink, Router, localizedRoutes } from '../../lib/routes';
import { RouteConfig } from '../../lib/routes/types';

type Props = {
  route: string;
  params?: Record<string, any>;
  passHref?: boolean;
  replace?: boolean;
};

export const staticRouteMap = {
  startPage: 'home',
  buyGiftCardPage: 'buy-gift-card',
  redeemCouponPage: 'redeem-coupon',
  buyProductPage: 'buy',
  stationMapPage: 'station-map',
  openDataPage: 'open-data',
  howItWorksPage: 'how-it-works',
  businessPage: 'business',
  termsOfUsePage: 'terms-of-use',
  termsOfPurchasePage: 'terms-of-purchase'
};

const routeMap = {
  ...staticRouteMap,
  articlePage: 'article'
};

// find a route or nested route
function findRoute(name: string, config: RouteConfig) {
  if (!name) {
    return;
  }
  if (config[name]) {
    return config[name];
  }

  const [route1, route2] = name.split('.');

  if (
    config[route1] &&
    config[route1].children &&
    // @ts-ignore
    config[route1].children[route2]
  ) {
    return config[route1];
  }
}

export function getSlugs(
  locale: string,
  routeName: string
): { slug?: string; slug2?: string } {
  let slug, slug2;

  const localizedRoute = findRoute(routeName, localizedRoutes);
  if (localizedRoute && localizedRoute.slug) {
    slug = localizedRoute.slug[locale];
    const { children } = localizedRoute;

    if (children) {
      const [, route2] = routeName.split('.');
      const nestedRoute = children[route2];
      if (nestedRoute && nestedRoute.slug) {
        slug2 = nestedRoute.slug[locale];
      }
    }
  }

  return { slug, slug2 };
}

export function getProps(props: {
  route: string;
  params?: any;
  locale: string;
  defaultLocale: string | null;
}): { route: string; params: Record<string, any> } {
  const { locale, defaultLocale } = props;
  const route = routeMap[props.route] || props.route;
  const params: { locale?: string; slug?: string; slug2?: string } = {
    ...props.params
  };
  const { slug, slug2 } = getSlugs(locale, route);

  if (locale !== defaultLocale) {
    params.locale = locale;
  }

  if (slug) {
    params.slug = slug;
  }
  if (slug2) {
    params.slug2 = slug2;
  }

  return { route, params };
}

const allowedQueryProps = ['__previewMode'];

const Link: FC<Props> = ({ route, params, ...props }) => {
  const intl = useIntl();
  const router = useRouter();
  const { locale, defaultLocale } = intl;

  const query = (router && router.query) || {};

  // keep all url params from allowedQueryProps
  const queryParamsToKeep = Object.keys(query).reduce((accum, key) => {
    if (allowedQueryProps.includes(key)) {
      accum[key] = query[key];
    }
    return accum;
  }, {});

  const routeProps = getProps({
    route,
    locale,
    defaultLocale,
    params: { ...params, ...queryParamsToKeep }
  });
  return <RouteLink {...props} {...routeProps} />;
};

export const replaceRoute = ({
  route,
  params,
  options,
  intl
}: {
  route: string;
  params?: Record<string, any>;
  options?: Record<string, any>;
  intl: {
    locale: string;
    defaultLocale: string | null;
  };
}) => {
  const routeProps = getProps({
    route,
    params,
    locale: intl.locale,
    defaultLocale: intl.defaultLocale
  });
  return Router.replaceRoute(routeProps.route, routeProps.params, options);
};

export const pushRoute = ({
  route,
  params,
  options,
  intl
}: {
  route: string;
  params?: Record<string, any>;
  options?: Record<string, any>;
  intl: {
    locale: string;
    defaultLocale: string | null;
  };
}) => {
  const routeProps = getProps({
    route,
    params,
    locale: intl.locale,
    defaultLocale: intl.defaultLocale
  });
  return Router.pushRoute(routeProps.route, routeProps.params, options);
};

Link.defaultProps = {
  params: {}
};

export default Link;
