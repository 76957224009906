import { gql } from '@apollo/client';

export const NAVIGATION_FRAGMENT = gql`
  fragment Navigation on SanitySettingsNavigation {
    page {
      _id
      _type
      slug {
        current
      }
    }
    externalLink
    showIntercom
    title
    _key
  }
`;
