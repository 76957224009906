import { encodedGdanskBikeImg } from '../encoded-marker-images/inurba-gdansk/bike';
import { encodedGdanskEbikeImg } from '../encoded-marker-images/inurba-gdansk/ebike';

const CustomMarkers = [
  {
    src: encodedGdanskBikeImg,
    name: 'inurba-gdansk-bike'
  },
  {
    src: encodedGdanskEbikeImg,
    name: 'inurba-gdansk-ebike'
  }
];

export default CustomMarkers;
