import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Warning as IconWarning } from '@urbaninfrastructure/react-icons';
import { MapOverlay } from '../MapOverlay';
import { MAP_PADDING } from '../MapLayout';
import messages from '../messages';
import { Anchor, Box, Flex, Link, Text } from '../../';
import { useSettingsQuery } from '../../../lib/core/SettingsQuery';

export interface AnimatedMarkersTextProps {
  numberOfCurrentTrips: number;
  inSeason?: boolean | null;
}

export const AnimatedMarkersText: FC<AnimatedMarkersTextProps> = props => {
  const { numberOfCurrentTrips, inSeason } = props;
  const { data } = useSettingsQuery();

  return (
    <MapOverlay
      bottom={MAP_PADDING}
      left={MAP_PADDING}
      right={MAP_PADDING}
      zIndex={1}
      top="auto"
    >
      <Flex justifyContent="space-around">
        <Box bg="white" textAlign="center" borderRadius="md" p={2} fontSize={1}>
          <Flex alignItems="center">
            <IconWarning mr={2} />
            {inSeason ? (
              <Text>
                <FormattedMessage
                  {...messages.simulatedTrips}
                  values={{
                    numberOfCurrentTrips,
                    openDataLink: (
                      <Link route="open-data">
                        <Anchor>
                          <FormattedMessage {...messages.openDataLink} />
                        </Anchor>
                      </Link>
                    )
                  }}
                />
              </Text>
            ) : (
              <FormattedMessage
                {...messages.seasonClosed}
                values={{
                  systemName:
                    (data && data.settings && data.settings.title) || 'System'
                }}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    </MapOverlay>
  );
};
