import React, { FC } from 'react';
import { MapPopup } from './MapPopup';
import styled from 'styled-components';
import { Box, useMapContext } from '../../components';
import { useStyledTheme } from '../../lib/hooks';
import { DotInfo } from './DotInfo';
import { AnimatedMarkers, AnimatedMarkersText } from './AnimatedMarkers';
import { completedTripSubscription_completedTrip } from '../../core-types';
import { useCurrentUser } from '../../lib/core/useCurrentUser';
import { MapControls } from './MapControls';

const MapContainer = styled(Box)`
  position: absolute !important;
`;

export const MAP_PADDING = 10;

type MapLayoutProps = {
  completedTrip?: completedTripSubscription_completedTrip;
  enableAnimatedTrips: boolean;
  inSeason?: boolean;
};

export const MapLayout: FC<MapLayoutProps> = props => {
  const { enableAnimatedTrips, inSeason } = props;

  const {
    mapRef,
    mapContainerRef,
    isMapLoaded,
    completedTrip
  } = useMapContext();

  const theme = useStyledTheme();
  const { currentUser } = useCurrentUser();
  const superpowers = (currentUser && currentUser.superpowers) || false;

  const renderAnimatedMarkers = Boolean(
    mapRef.current &&
      isMapLoaded &&
      enableAnimatedTrips &&
      completedTrip &&
      completedTrip.direction
  );

  return (
    <>
      <MapControls />
      <MapPopup anchor="top">
        <DotInfo superpowers={superpowers} />
      </MapPopup>
      <MapContainer
        top={0}
        right={0}
        bottom={0}
        left={0}
        // @ts-ignore
        ref={mapContainerRef}
      />

      {renderAnimatedMarkers && (
        <AnimatedMarkers
          primaryDark={theme.colors.primaryDark}
          map={mapRef.current}
          trip={completedTrip}
          tripEnded={() => {
            return;
          }}
        >
          {numberOfCurrentTrips => {
            return (
              <AnimatedMarkersText
                numberOfCurrentTrips={numberOfCurrentTrips}
                inSeason={inSeason}
              />
            );
          }}
        </AnimatedMarkers>
      )}
    </>
  );
};
