import { Dispatch, SetStateAction } from 'react';
import { SystemConfig } from '../../../lib/types';

export const BIKE_DOTS_SOURCE_ID = 'bike-dots-source';
export const BIKE_NAMES_LAYER_ID = 'bikes-names';
export const BIKE_DOTS_LAYER_ID = 'bike-dots';

export function getBikeDotsStyle(systemConfig: SystemConfig) {
  const bikeDots: mapboxgl.AnyLayer = {
    id: BIKE_DOTS_LAYER_ID,
    source: BIKE_DOTS_SOURCE_ID,
    type: 'symbol',
    layout: {
      'icon-image': `${systemConfig.id}-bike`,
      'icon-allow-overlap': true,
      'icon-offset': [0, -6],
      'icon-size': {
        stops: [
          [8, 0.1],
          [10, 0.15],
          [12, 0.2],
          [16, 0.4],
          [18, 0.5]
        ]
      }
    }
  };
  return bikeDots;
}

export const bikeNames: mapboxgl.AnyLayer = {
  id: BIKE_NAMES_LAYER_ID,
  type: 'symbol',
  source: BIKE_DOTS_SOURCE_ID,
  layout: {
    'text-field': [
      'case',
      ['==', ['get', 'batteryCharge'], null],
      ['get', 'name'],
      ['concat', ['get', 'name'], '\n', ['get', 'batteryCharge'], '%']
    ],
    'text-size': 10,
    'text-offset': [0, 3]
  },
  paint: {
    'text-color': '#000'
  }
};

export function handleClickEvent(
  map: mapboxgl.Map,
  setActiveDotId: Dispatch<SetStateAction<null>>,
  layerId: string
) {
  map.on('click', layerId, e => {
    const id = e?.features?.[0]?.properties?.id;
    setActiveDotId(id);
  });
  // Change the cursor to a pointer when the mouse is over the places layer.
  map.on('mouseenter', layerId, () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  // Change it back to a pointer when it leaves.
  map.on('mouseleave', layerId, () => {
    map.getCanvas().style.cursor = '';
  });
}
