import * as React from 'react';
import FormattedDuration from 'react-intl-formatted-duration';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

type Minutes = number;

export const MatrixPeriodDuration: React.FC<{
  end: Minutes;
  first: boolean;
  infinite: boolean;
  start: Minutes;
}> = ({ end, first, infinite, start }) => {
  if (infinite) {
    return first ? (
      <FormattedMessage {...messages.ride} />
    ) : (
      <>
        <FormattedDuration seconds={start * 60} format="{hours} {minutes}" /> +
      </>
    );
  }

  return (
    <>
      {start === 0 && 0}
      {start !== 0 && start <= 60 && <FormattedDuration seconds={start * 60} />}
      {start > 60 && (
        <FormattedDuration seconds={start * 60} format="{hours} {minutes}" />
      )}
      –{end <= 60 && <FormattedDuration seconds={end * 60} />}
      {end > 60 && (
        <FormattedDuration seconds={end * 60} format="{hours} {minutes}" />
      )}
    </>
  );
};
