import { FC } from 'react';
import { useIntl, FormattedNumber, IntlShape } from 'react-intl';

type Props = {
  value: number;
  precision?: number;
};

function getPrecision(value: number): number {
  return value % 1 === 0 ? 0 : 2;
}

export function formatCurrency({
  value,
  precision = getPrecision(value),
  intl
}: Props & { intl: IntlShape }) {
  const currency =
    intl.formats &&
    intl.formats.number &&
    intl.formats.number.currency.currency;

  return intl.formatNumber(value, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  });
}

export const FormattedCurrency: FC<Props> = ({
  value,
  precision = getPrecision(value)
}) => {
  const intl = useIntl();
  const currency =
    intl.formats &&
    intl.formats.number &&
    intl.formats.number.currency.currency;

  return (
    <FormattedNumber
      value={value}
      style="currency"
      currency={currency}
      minimumFractionDigits={precision}
      maximumFractionDigits={precision}
    />
  );
};
