import nextRoutes from 'next-routes';
import config from './config';
import { addRoutes, ROOT_PARAM } from './add-routes';

const routes = new nextRoutes();

addRoutes(routes, config);

routes.add('post-checkout', `${ROOT_PARAM}/post-checkout`);
routes.add('licenses-android', `${ROOT_PARAM}/licenses-android`);
routes.add('licenses-ios', `${ROOT_PARAM}/licenses-ios`);

// article catches rest
routes.add('article', `${ROOT_PARAM}/:slug`);

routes.add('profile.trips.plain', `${ROOT_PARAM}/profile/trips/:tripId/plain`);

routes.add('profile.trips.trip', `${ROOT_PARAM}/profile/trips/:tripId`);

export const localizedRoutes = config;

export const Link = routes.Link;
export const Router = routes.Router;

export function match(path: string): any {
  // @ts-ignore internal function used, beware!
  return routes.match(path);
}

export default routes;
