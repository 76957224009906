import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import { useMapContext } from './MapContext';

export interface MapPopupProps {
  anchor: 'top';
}

export const MapPopup: FC<MapPopupProps> = props => {
  const { children, anchor } = props;

  const { mapRef, activeDotFeature } = useMapContext();

  const containerRef = React.useRef(document.createElement('div'));
  const popupRef = React.useRef<mapboxgl.Popup | null>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!activeDotFeature) return () => {};

    popupRef.current = new mapboxgl.Popup({
      closeButton: true,
      // closeOnClick,
      // offset,
      maxWidth: 'auto',
      offset: 10,
      anchor
    });

    if (popupRef.current) {
      popupRef.current.setDOMContent(containerRef.current);
      if (activeDotFeature.geometry.type === 'Point') {
        popupRef.current.setLngLat([
          activeDotFeature.geometry.coordinates[0],
          activeDotFeature.geometry.coordinates[1]
        ]);
      }

      if (mapRef.current) {
        popupRef.current.addTo(mapRef.current);
      }
    }

    return () => {
      popupRef.current && popupRef.current.remove();
    };
  }, [activeDotFeature, anchor]);

  return ReactDOM.createPortal(children, containerRef.current);
};
