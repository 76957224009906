import styled from 'styled-components';
import { Box } from '..';

export const MapOverlay = styled(Box)`
   {
    position: absolute;
    z-index: 3;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
  }
`;
