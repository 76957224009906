import * as React from 'react';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import upperFirst from 'lodash/upperFirst';
import { useIntl } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';

type DateInput = Date;
type TimeZoneName = 'short' | 'long';
type Props = {
  value: DateInput | null;
  /** threshold defines how many hours old before it should not show relative time */
  threshold?: number;
  showSeconds?: boolean;
  showTimeZone?: boolean;
  hideTime?: boolean;
  detailedDate?: boolean;
  futureDateMessage?: React.ReactNode;
  upperCaseFirst?: boolean;
};

function formatDateTime(
  intl,
  date: DateInput,
  timeZoneName?: TimeZoneName | null
): string {
  return intl.formatDate(date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZoneName
  });
}

function formatShortTime(
  intl,
  date: DateInput,
  timeZoneName?: TimeZoneName | null,
  showSeconds?: boolean,
  hideTime?: boolean
): string {
  return intl.formatTime(date, {
    hour: hideTime ? undefined : '2-digit',
    minute: hideTime ? undefined : '2-digit',
    second: !hideTime && showSeconds ? '2-digit' : undefined,
    hour12: false,
    timeZoneName
  });
}

function formatShortDateTime(
  intl,
  date: DateInput,
  timeZoneName?: TimeZoneName | null,
  showSeconds?: boolean,
  hideTime?: boolean,
  detailedDate?: boolean
): string {
  return intl.formatDate(date, {
    month: detailedDate ? 'long' : 'short',
    day: 'numeric',
    weekday: detailedDate ? 'long' : undefined,
    hour: hideTime ? undefined : '2-digit',
    minute: hideTime ? undefined : '2-digit',
    second: !hideTime && showSeconds ? '2-digit' : undefined,
    hour12: false,
    timeZoneName
  });
}

const ONE_HOUR = 60 * 60 * 1000; /* ms */

const Time = ({
  value,
  showTimeZone,
  showSeconds,
  threshold = 1,
  hideTime,
  detailedDate,
  futureDateMessage,
  upperCaseFirst = false
}: Props) => {
  const intl = useIntl();
  if (!value) {
    return null;
  }
  const date = typeof value === 'string' ? parseISO(value) : new Date(value);
  const isOlderThanThreshold = +new Date() - +date > ONE_HOUR * threshold;
  const isInTheFuture = isBefore(new Date(), date);
  const isToday = isSameDay(new Date(), date);
  let timeZoneName;

  if ((!isOlderThanThreshold && !isInTheFuture) || showTimeZone) {
    timeZoneName = 'short';
  }

  const formattedDateTime = formatDateTime(intl, value, 'short');
  const formattedShortTime = formatShortTime(
    intl,
    value,
    timeZoneName,
    showSeconds,
    hideTime
  );
  const formattedShortDateTime = formatShortDateTime(
    intl,
    value,
    timeZoneName,
    showSeconds,
    hideTime,
    detailedDate
  );

  const casing = (value: string) =>
    upperCaseFirst ? upperFirst(value) : value;

  const { value: relativeValue, unit } = selectUnit(value);

  return (
    <time dateTime={value.toISOString()} title={formattedDateTime}>
      {isOlderThanThreshold ? (
        isToday ? (
          casing(formattedShortTime)
        ) : (
          casing(formattedShortDateTime)
        )
      ) : isInTheFuture ? (
        futureDateMessage ? (
          <>
            {futureDateMessage} {formattedShortDateTime}
          </>
        ) : (
          casing(formattedShortDateTime)
        )
      ) : (
        casing(intl.formatRelativeTime(relativeValue, unit))
      )}
    </time>
  );
};

export default Time;
