import * as React from 'react';

const BikeMiLogo = ({
  width = 180,
  height = 54,
  alt = 'VeronaBike'
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      width={width}
      height={height}
      src="/static/assets/veronaBikeLogo.svg"
      alt={alt}
    />
  );
};

export default BikeMiLogo;
