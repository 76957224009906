import * as React from 'react';
import along from '@turf/along';
import { lineString } from '@turf/helpers';
import { completedTripSubscription_completedTrip } from '../../../core-types';

type Props = {
  speed: number;
  map: any;
  trip?: completedTripSubscription_completedTrip | null;
  primaryDark: string;
  tripEnded: (endDockGroupId: string) => void;
  getNow: () => number;
  children?: (numberOfCurrentTrips: number) => React.ReactNode;
};

type CurrentTrip = {
  id: string;
  routeLine: GeoJSON.LineString;
  origin: [number, number];
  destination: [number, number];
  endDockGroupId: string | null;
  startedAt: string | null;
  endedAt: string | null;
  point: GeoJSON.Feature<GeoJSON.Point>;
};

type State = {
  currentTrips: CurrentTrip[];
};

export const SOURCE_ID = 'animated-markers-source';
export const LAYER_ID = 'animated-markers-layer';
const MARKER_SIZE = 4;

function createCurrentTrip(trip) {
  if (!trip || !trip.direction || !trip.endDockGroup) {
    return;
  }
  const { geometry } = trip.direction;

  if (!geometry || geometry.length < 2) {
    return;
  }

  const routeLine = lineString(
    geometry.reduce((accum, coord) => {
      if (coord && coord.lng && coord.lat) {
        accum.push([coord.lng, coord.lat]);
      }
      return accum;
    }, [])
  );

  const origin = geometry[0] &&
    geometry[0].lng &&
    geometry[0].lat && [geometry[0].lng, geometry[0].lat];
  const lastCoord = geometry[geometry.length - 1];
  const destination = lastCoord &&
    lastCoord.lng &&
    lastCoord.lat && [lastCoord.lng, lastCoord.lat];

  if (!origin || !destination) {
    return;
  }

  if (origin.length === 0 || destination.length === 0) {
    return;
  }

  return {
    id: trip.id,
    routeLine,
    origin,
    destination,
    endDockGroupId: trip.endDockGroup ? trip.endDockGroup.id : null,
    startedAt: trip.startedAt,
    endedAt: trip.endedAt,
    point: []
  };
}

function validateLineString(lineString) {
  if (!lineString || !lineString.geometry || !lineString.geometry.coordinates) {
    return false;
  }
  return lineString.geometry.coordinates.some(coordinate => {
    if (!coordinate) {
      return false;
    }
    const [longitude, latitude] = coordinate;
    if (!longitude || !latitude) {
      return false;
    }
    return true;
  });
}

export class AnimatedMarkers extends React.Component<Props, State> {
  rafId?: number;

  static defaultProps = {
    speed: 4,
    getNow: () => Date.now()
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentTrips: []
    };
  }

  componentDidMount() {
    const { map, primaryDark } = this.props;
    if (!map.getSource(SOURCE_ID)) {
      map.addSource(SOURCE_ID, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
    }
    if (!map.getLayer(LAYER_ID)) {
      map.addLayer({
        id: LAYER_ID,
        source: SOURCE_ID,
        type: 'circle',
        paint: {
          'circle-color': primaryDark,
          'circle-radius': MARKER_SIZE
        }
      });
    }

    this.startLoop();
  }
  componentDidUpdate() {
    this.startLoop();
  }
  componentWillUnmount() {
    this.stopLoop();
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { trip } = nextProps;
    if (!trip) {
      return null;
    }
    if (
      trip.startDockGroup &&
      trip.endDockGroup &&
      trip.startDockGroup.id === trip.endDockGroup.id
    ) {
      return null;
    }
    const { currentTrips } = prevState;
    // if trip already in currentTrips, abort
    if (currentTrips.some(t => t && t.id === trip.id)) {
      return null;
    }
    const newCurrentTrip = createCurrentTrip(trip);
    if (!newCurrentTrip) {
      return null;
    }
    return {
      currentTrips: [...currentTrips, newCurrentTrip]
    };
  }

  animate = () => {
    try {
      const { speed, map, tripEnded, getNow } = this.props;
      const now = getNow();
      const currentTrips = this.state.currentTrips.reduce<CurrentTrip[]>(
        (accum, currentTrip) => {
          if (currentTrip && currentTrip.startedAt && currentTrip.endedAt) {
            const { endedAt, startedAt } = currentTrip;
            const start = Date.parse(startedAt);
            const end = Date.parse(endedAt);

            const driven = now - end;
            const timeSpan = end - start;
            const fraction = driven / timeSpan;
            const distanceDriven = Math.max(0, speed * fraction);
            // const distanceDriven =
            //   speed * currentTrip.routeLine.geometry.coordinates.length * fraction

            // const lineIndex = parseInt(
            //   (fraction *
            //     100) *
            //     (currentTrip.routeLine.geometry.coordinates.length / 100)
            // )

            // if performance is an issue, we can switch to mapbox/cheap-ruler, which is mnuch faster, at the cost of precision
            // const point = turfPoint(
            //   this.ruler.along(
            //     currentTrip.routeLine.geometry.coordinates,
            //     distanceDriven
            //   )
            // )

            if (!validateLineString(currentTrip.routeLine)) {
              return accum;
            }
            const point = along(currentTrip.routeLine, distanceDriven, {
              units: 'kilometers'
            });

            currentTrip.point = point;

            const { destination, endDockGroupId } = currentTrip;
            if (
              point.geometry.coordinates[0] === destination[0] &&
              point.geometry.coordinates[1] === destination[1]
            ) {
              if (endDockGroupId) {
                tripEnded(endDockGroupId);
              }
            } else {
              accum.push(currentTrip);
            }
          }
          return accum;
        },
        []
      );

      const source = map.getSource(SOURCE_ID);

      if (!source) {
        return;
      }

      source.setData({
        type: 'FeatureCollection',
        features: currentTrips.map(c => c.point)
      });

      this.setState({ currentTrips }, () => {
        if (currentTrips.length > 0) {
          this.rafId = requestAnimationFrame(this.animate);
        } else {
          this.stopLoop();
        }
      });
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Could not animate point', err);
      }
    }
  };

  stopLoop = () => {
    this.rafId && window.cancelAnimationFrame(this.rafId);
    delete this.rafId;
  };

  startLoop = () => {
    if (!this.rafId) {
      this.rafId = requestAnimationFrame(this.animate);
    }
  };

  render() {
    if (this.props.children) {
      return this.props.children(this.state.currentTrips.length);
    }
    return null;
  }
}
