// a set of common translations. all components should be prefix with "I18N"

import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { globalMessages } from '../lib/messages';

type Props = {
  tagName?: React.ElementType<any>;
};

export const i18nMessages = defineMessages({
  profile: {
    id: 'components.buttons-i18n.profile',
    defaultMessage: 'Profile'
  },
  receipts: {
    id: 'receipts',
    defaultMessage: 'Receipts'
  },
  pinCode: {
    id: 'pin_code',
    defaultMessage: 'PIN code'
  }
});

export const durationMessages = defineMessages({
  seconds: {
    id: 'plural.value_seconds',
    defaultMessage:
      '{seconds, plural, one {{seconds} second} other {{seconds} seconds}}',
    description: 'Pluralised seconds with value (eg 1 second)'
  },
  minutes: {
    id: 'plural.value_minutes',
    defaultMessage:
      '{minutes, plural, one {{minutes} minute} other {{minutes} minutes}}',
    description: 'Pluralised minutes with value (eg 1 minute)'
  },
  hours: {
    id: 'plural.value_hours',
    defaultMessage: '{hours, plural, one {{hours} hour} other {{hours} hours}}',
    description: 'Pluralised hours with value (eg 1 hour)'
  },
  days: {
    id: 'plural.value_days',
    defaultMessage: '{days, plural, one {{days} day} other {{days} days}}',
    description: 'Pluralised days with value (eg 1 day)'
  }
});

export const I18NBuy = (props: Props) => (
  <FormattedMessage
    id="buy"
    defaultMessage="Buy"
    description="Generic buy message"
    {...props}
  />
);

export const I18NEdit = (props: Props) => (
  <FormattedMessage
    id="components.buttons-i18n.edit"
    defaultMessage="Edit"
    description="Generic edit message"
    {...props}
  />
);

export const I18NSave = (props: Props) => (
  <FormattedMessage {...globalMessages.save} {...props} />
);

export const I18NCancel = (props: Props) => (
  <FormattedMessage
    id="cancel"
    defaultMessage="Cancel"
    description="Generic cancel message"
    {...props}
  />
);

export const I18NLogOut = (props: Props) => (
  <FormattedMessage
    id="log_out"
    defaultMessage="Log out"
    description="Generic log out message"
    {...props}
  />
);

export const I18NPluralValueMinutes = (props: {
  values: { minutes: number };
}) => <FormattedMessage {...durationMessages.minutes} {...props} />;

export const I18NPluralStations = (props: {
  values: { count: number | string };
}) => (
  <FormattedMessage
    id="plural.stations"
    defaultMessage="{count, plural, one {station} other {stations}}"
    description="Pluralised stations (eg station)"
    {...props}
  />
);

export const I18NPluralTrips = (props: {
  values: { count: number | string };
}) => (
  <FormattedMessage
    id="plural.trips"
    defaultMessage="{count, plural, one {trip} other {trips}}"
    description="Pluralised trips (eg trip)"
    {...props}
  />
);

export const I18NProfile = (props: Props) => (
  <FormattedMessage {...i18nMessages.profile} {...props} />
);

export const I18NReceipts = (props: Props) => (
  <FormattedMessage {...i18nMessages.receipts} {...props} />
);

export const I18NPinCode = (props: Props) => (
  <FormattedMessage {...i18nMessages.pinCode} {...props} />
);

export const I18NContinue = () => {
  return (
    <FormattedMessage
      id="components.buttons-i18n.continue"
      defaultMessage="Continue"
    />
  );
};

export const I18NClose = () => {
  return <FormattedMessage id="close" defaultMessage="Close" />;
};

export const I18NRemove = () => {
  return <FormattedMessage id="remove" defaultMessage="Remove" />;
};
