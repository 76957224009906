import { gql } from '@apollo/client';

export const CURRENT_SUBSCRIPTION_FRAGMENT = gql`
  fragment CurrentSubscription on ProductSubscription {
    id
    product {
      id
      name
      durationMethod
      durationMins
      rentalMeterType
      price
      validTo
      renewOptMethod
      isRenewable
    }
    state
    validFrom
    validTo
    createdAt
  }
`;
