import { useQuery, gql, QueryResult, QueryHookOptions } from '@apollo/client';
import { CurrentUser, currentUserQuery } from '../../core-types';
import { CURRENT_USER_FRAGMENT } from './fragments/currentUser';

// This query should never be done server side
export const CURRENT_USER_QUERY = gql`
  ${CURRENT_USER_FRAGMENT}
  query currentUserQuery {
    intercomSettings
    currentUser {
      ...CurrentUser
    }
  }
`;

export function useCurrentUser(
  options?: Omit<QueryHookOptions<currentUserQuery>, 'ssr'>
): QueryResult<currentUserQuery, Record<string, any>> & {
  currentUser: CurrentUser | null;
} {
  const queryResult = useQuery<currentUserQuery>(CURRENT_USER_QUERY, {
    ssr: false,
    ...options
  });
  return {
    ...queryResult,
    currentUser: queryResult.data?.currentUser || null
  };
}
