import { Input as UIKitInput } from '@urbaninfrastructure/react-ui-kit';
import styled from 'styled-components';

const Input = styled(UIKitInput)``;

Input.defaultProps = {
  alternative: true
};

export default Input;
