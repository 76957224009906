import { useCallback, useState } from 'react';

const useBool = (
  defaultValue = false
): [boolean, (...args: any[]) => void, () => void, () => void] => {
  const [value, setValue] = useState<boolean>(defaultValue);

  const setOrSwitch = useCallback(
    (...args: any[]) =>
      setValue(prev => {
        if (args.length) {
          return Boolean(args[0]);
        }
        return !prev;
      }),
    []
  );

  const setTrue = useCallback(() => setValue(true), []);

  const setFalse = useCallback(() => setValue(false), []);

  return [value, setOrSwitch, setTrue, setFalse];
};

export default useBool;
