import * as React from 'react';
import { SystemConfig } from './types';

type SystemConfigContextType = {
  systemConfig: SystemConfig | null;
  baseUrl: string;
};

export const SystemConfigContext = React.createContext<SystemConfigContextType>(
  {
    systemConfig: null,
    baseUrl: ''
  }
);

export const SystemConfigProvider = SystemConfigContext.Provider;

export function SystemConfigConsumer({
  children
}: {
  children: (SystemConfigContextType) => React.ReactNode;
}) {
  return (
    <SystemConfigContext.Consumer>
      {({ systemConfig, baseUrl }) => {
        if (!systemConfig) {
          throw new Error('Could not load systemConfig');
        }
        return children({ systemConfig, baseUrl });
      }}
    </SystemConfigContext.Consumer>
  );
}

export function useSystemConfig(): SystemConfig {
  const { systemConfig } = React.useContext(SystemConfigContext);
  if (!systemConfig) {
    throw new Error('Could not load systemConfig');
  }
  return systemConfig;
}

export function useBaseUrl(): string {
  const { baseUrl } = React.useContext(SystemConfigContext);
  return baseUrl;
}
