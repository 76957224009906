import { defineMessages } from 'react-intl';

export default defineMessages({
  bikes: {
    id: 'modules.StationMap.views.bikes',
    defaultMessage: 'bikes'
  },
  traditionalBikes: {
    id: 'modules.StationMap.views.traditional_bikes',
    defaultMessage: 'Traditional bikes'
  },
  parking: {
    id: 'modules.StationMap.views.parking',
    defaultMessage: 'Parking'
  },
  live: {
    id: 'modules.StationMap.views.live',
    defaultMessage: 'Live'
  },
  simulatedTrips: {
    id: 'modules.StationMap.simulatedTrips',
    defaultMessage:
      '{numberOfCurrentTrips, select, 0 {} other {{numberOfCurrentTrips, number}}} {numberOfCurrentTrips, plural, =0 {Simulated trips} one {simulated trip} other {simulated trips}}, based on {openDataLink}'
  },
  openDataLink: {
    id: 'modules.StationMap.openDataLink',
    defaultMessage: 'open data'
  },
  seasonClosed: {
    id: 'modules.StationMap.SeasonClosed',
    defaultMessage: '{systemName} is closed for the season'
  },
  bikesAvailability: {
    id: 'modules.StationMap.availability.bikes',
    defaultMessage: '{count, plural, one {bike} other {bikes}}'
  },
  eBikesAvailability: {
    id: 'modules.StationMap.availability.eBikes',
    defaultMessage:
      '{count, plural, one {electric bike} other {electric bikes}}'
  },
  eBikesWithChildSeatAvailability: {
    id: 'modules.StationMap.availability.eBikesWithChildSeatAvailability',
    defaultMessage:
      '{count, plural, one {electric bike with child seat} other {electric bikes with child seat}}'
  },
  scootersAvailability: {
    id: 'plural_scooter',
    defaultMessage: '{plural_scooter, plural, one {scooter} other {scooters}}'
  },
  parkingAvailability: {
    id: 'plural_available',
    defaultMessage:
      '{plural_available, plural, one {available} other {available}}'
  },
  filterBy: {
    id: 'filter_by',
    defaultMessage: 'Filter by'
  },
  virtualSingular: {
    id: 'virtual_singular',
    defaultMessage: 'Virtual'
  },
  physicalSingular: {
    id: 'physical_singular',
    defaultMessage: 'Physical'
  },
  eBikes: {
    id: 'modules.StationMap.availability.eBikes',
    defaultMessage:
      '{count, plural, one {electric bike} other {electric bikes}}'
  },
  eBikesWithChildSeat: {
    id: 'modules.StationMap.availability.eBikesWithChildSeatAvailability',
    defaultMessage:
      '{count, plural, one {electric bike with child seat} other {electric bikes with child seat}}'
  }
});
