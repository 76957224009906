import { NAVIGATION_FRAGMENT } from './fragments/navigation';
import { SETTINGS_FRAGMENT } from './fragments/settings';
import { settings } from '../../core-types';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const SETTINGS_QUERY = gql`
  ${NAVIGATION_FRAGMENT}
  ${SETTINGS_FRAGMENT}

  query settings {
    system {
      id
      name
      cityName
      type
      phoneCountryCode
      parsedContactNumber {
        formattedNumber
      }
      contactEmail
      iosAppUrl
      androidAppUrl
      businessUrl
      closedReason
      inSeason
      season {
        startDate
      }
      publicDomain
      standardRentalMins
      sponsor
      GBFS
      publicTripHistory
      intercomHelpSite
      walletEnablement
      registrationFeeEnabled
      registrationFeeAmount
      registrationEnabled
      userEmailConfirmationRequired
      workingHours {
        startDate
        endDate
      }
      paymentProvider {
        __typename
      }
      configuration {
        showAgreedToSendDataToThirdPartiesCheckbox
      }
      legalConsents {
        type
        enforcement
      }
      rfidAssignMethod
      availablePaymentMethods
    }
    settings: sanitySettings {
      ...Settings
    }
  }
`;

export const useSettingsQuery = (options?: QueryHookOptions<settings>) =>
  useQuery<settings>(SETTINGS_QUERY, options);
