import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  Button,
  Link,
  ButtonGroup,
  useMapContext,
  Flex,
  Dialog,
  IconButton,
  Box
} from '../..';
import { MapOverlay } from '../MapOverlay';
import { Stats } from './Stats';
import messages from '../messages';
import { MAP_PADDING } from '../MapLayout';
import { FiltersList } from './FiltersList';
import { Cog as CogIcon } from '@urbaninfrastructure/react-icons';

const MapControlsButton = styled(Button)`
  margin: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 12px;
  }
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  margin-bottom: 0.5rem;
  }
`;

const StyledButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  z-index: 3;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 1.8125rem;
    height: 1.8125rem;
  }
`;

const heading = (
  <Box mr={2}>
    <FormattedMessage {...messages.filterBy} />
  </Box>
);

export const MapControls: FC = () => {
  const { disableMapControls, view, showLiveMap } = useMapContext();

  if (disableMapControls) return null;

  return (
    <React.Fragment>
      <MapOverlay top={MAP_PADDING} left={MAP_PADDING}>
        <ButtonGroup segment mb={2}>
          <Link route="station-map" params={{ view: 'bikes' }} passHref>
            <MapControlsButton
              small
              shape="cool"
              variant={view === 'bikes' ? 'primary' : undefined}
              mb={2}
            >
              <FormattedMessage {...messages.bikes} />
            </MapControlsButton>
          </Link>
          <Link route="station-map" params={{ view: 'parking' }} passHref>
            <MapControlsButton
              small
              shape="cool"
              variant={view === 'parking' ? 'primary' : undefined}
              mb={2}
            >
              <FormattedMessage {...messages.parking} />
            </MapControlsButton>
          </Link>
          {showLiveMap && (
            <Link route="station-map" params={{ view: 'live' }} passHref>
              <MapControlsButton
                small
                shape="cool"
                variant={view === 'live' ? 'primary' : undefined}
                mb={2}
              >
                <FormattedMessage {...messages.live} />
              </MapControlsButton>
            </Link>
          )}
        </ButtonGroup>
        <StyledFlex bg="white" borderRadius="md">
          {view === 'live' && <Stats />}
        </StyledFlex>
      </MapOverlay>

      {view !== 'live' && (
        <Dialog
          heading={heading}
          size="medium"
          showCloseButton={true}
          renderOpenButton={openPortal => (
            <StyledButton
              rounded
              variant="primary"
              Icon={CogIcon}
              aria-label="check"
              color="white"
              onClick={openPortal}
              width={2}
            />
          )}
        >
          {() => {
            return (
              <React.Fragment>
                <StyledFlex bg="white" borderRadius="md">
                  <FiltersList />
                </StyledFlex>
              </React.Fragment>
            );
          }}
        </Dialog>
      )}
    </React.Fragment>
  );
};
