import { FormattedMessage } from 'react-intl';

export function ShowTariffsMessage() {
  return (
    <FormattedMessage
      id="ProductPlanCard.showTariffs"
      defaultMessage="Show tariffs"
    />
  );
}
