// NOTE: This require will be replaced with `@sentry/browser` when
// process.browser === true thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/node';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { APP_ENV }
} = getConfig();

const ignoreErrors = [
  'ERR_NETWORK_ERROR',
  'ERR_INVALID_PHONE_NUMBER',
  'ERR_UNKNOWN_PHONE_NUMBER',
  'ERR_INVALID_PHONE_VERIFICATION_CODE',
  'ERR_IN_USE_PHONE_NUMBER',
  'ERR_VALUE_CODE_EXPIRED',
  'ERR_VALUE_CODE_USED',
  'ERR_VALUE_CODE_NO_LONGER_AVAILABLE',
  'ERR_VALUE_CODE_ACCOUNT_ALREADY_HAS_SUBSCRIPTION',
  'ERR_VALUE_CODE_UNKNOWN',
  'ERR_PAYMENT_DECLINED',
  'ERR_PAYMENT_ERROR',
  'ERR_USER_NO_AGREEMENT',
  'ERR_OBOS_MEMBERSHIP_PHONE_NUMBER_UNKNOWN',
  'ERR_RECORD_NOT_FOUND',
  // Google Translate extension
  // See: https://medium.com/@amir.harel/a-b-target-classname-indexof-is-not-a-function-at-least-not-mine-8e52f7be64ca
  'a[b].target.className.indexOf is not a function',
  /Blocked a frame with origin/,
  /vid_mate_check is not defined/,
  /mapbox-gl\/dist\/mapbox-gl/,
  /react-map-gl/,
  // Hopelessly trying to fix the issue described here: https://github.com/google/recaptcha/issues/269
  /UnhandledRejection: Non-Error promise rejection captured with value: Timeout/,
  // Apollo persisted queries https://www.apollographql.com/docs/apollo-server/performance/apq/
  'PersistedQueryNotFound'
];

const sentryOptions = {
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || '',
  environment: APP_ENV,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  ignoreErrors,
  blacklistUrls: [
    // Google reCAPTCHA
    /gstatic.com\/recaptcha/i,
    // Google translate
    /translate\.googleusercontent\.com/i,
    // Google Maps
    /maps\.googleapis\.com/i,
    // Intercom
    /js\.intercomcdn\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Firefox extensions
    /moz-extension/,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  // Temporary filter "ERR_GENERIC: options.hasOwnProperty is not a function" error (until we determine its nature)
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error &&
      error.message &&
      error.message.match(
        /ERR_GENERIC: options.hasOwnProperty is not a function/i
      )
    ) {
      //Discard the event and drop it completely
      return null;
    }
    return event;
  }
};

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init(sentryOptions);
}

export const captureException = (
  exception: any,
  extras?: Record<string, any>
): string => {
  if (extras) {
    Sentry.configureScope(scope => {
      scope.setExtras(extras);
    });
  }
  return Sentry.captureException(exception);
};
export const Severity = Sentry.Severity;
export { Sentry };
