import { forwardRef } from 'react';
import {
  Button as UIKitButton,
  ButtonAnchor as UIKitButtonAnchor,
  ButtonProps
} from '@urbaninfrastructure/react-ui-kit';

export const Button = forwardRef<
  HTMLButtonElement,
  Omit<
    ButtonProps &
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
    'ref'
  >
>(function Button({ shape = 'cool', color, ...props }, forwardedRef) {
  return (
    <UIKitButton
      ref={forwardedRef}
      shape={shape}
      color={color as any}
      {...props}
    />
  );
});

export const ButtonAnchor = forwardRef<
  HTMLAnchorElement,
  Omit<
    ButtonProps &
      React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >,
    'ref'
  >
>(function ButtonAnchor({ shape = 'cool', color, ...props }, forwardedRef) {
  return (
    <UIKitButtonAnchor
      ref={forwardedRef}
      shape={shape}
      color={color as any}
      {...props}
    />
  );
});
