import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  included: {
    id: 'ConnectionPriceMatrixes.included',
    defaultMessage: 'Included'
  },
  unlock: {
    id: 'unlock',
    defaultMessage: 'Unlock'
  },
  ride: {
    id: 'ConnectionPriceMatrixes.ride',
    defaultMessage: 'Ride'
  }
});

export const categoryNames = defineMessages({
  bike: {
    id: 'ConnectionPriceMatrixes.vehicleCategory.bike',
    defaultMessage: 'Bike'
  },
  ebike: {
    id: 'ConnectionPriceMatrixes.vehicleCategory.ebike',
    defaultMessage: 'Electric bike'
  },
  ebike_with_childseat: {
    id: 'ConnectionPriceMatrixes.vehicleCategory.ebike_with_childseat',
    defaultMessage: 'Electric bike with childseat'
  },
  scooter: {
    id: 'ConnectionPriceMatrixes.vehicleCategory.scooter',
    defaultMessage: 'Scooter'
  }
});
