import React, { FC } from 'react';
import { Text, Box } from '../../index';

export interface StatsItemProps {
  id: string;
  title: React.ReactNode;
  value: React.ReactNode;
  extra?: React.ReactNode;
  first?: boolean;
}

export const StatsItem: FC<StatsItemProps> = props => {
  const { id, title, value, extra, first } = props;

  return (
    <Box
      data-testid={id}
      px={4}
      pt={2}
      pb={1}
      mt={first ? 0 : 1}
      mb={1}
      borderColor="borderGray"
      borderStyle="solid"
      borderWidth={0}
      borderTopWidth={!first ? 1 : 0}
    >
      {value !== null && title !== null && (
        <Text id={id} mute small>
          {title}
        </Text>
      )}
      {value !== null && (
        <Text fontSize={2} aria-labelledby={id}>
          {value}
        </Text>
      )}
      {extra && (
        <Text mute fontSize={0} lineHeight={1.5}>
          {extra}
        </Text>
      )}
    </Box>
  );
};
