import React, { FC } from 'react';
import {
  FormattedMessage,
  FormattedNumber,
  FormattedDate,
  useIntl
} from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import subYears from 'date-fns/subYears';
import isSameDay from 'date-fns/isSameDay';
import { StatsItem } from './StatsItem';
import { LoadingOrErrorOrValue } from './LoadingOrErrorOrValue';
import { systemStatsQuery } from '../../../core-types';
import { secondsToMMSS } from '../../../lib/util';
import { useSettingsQuery } from '../../../lib/core/SettingsQuery';

export const SYSTEM_STATS_QUERY = gql`
  query systemStatsQuery {
    systemActiveTripCount {
      count
    }
    systemStats {
      tripsToday
      tripsYesterday
      highscoreTrips
      tripsThisYear
      tripsLastYear
      uniqueUsersToday
      highscoreUniqueUsers
      highscoreUniqueUsersDate
      medianDurationToday
      medianDurationThisYear
    }
  }
`;

export interface StatsProps {
  pollInterval?: number;
  now?: Date;
}

export const Stats: FC<StatsProps> = props => {
  const { pollInterval = 10000, now = new Date() } = props;

  const intl = useIntl();
  const { data: settingsData } = useSettingsQuery();
  const season =
    settingsData && settingsData.system && settingsData.system.season;

  const { data, loading, error } = useQuery<systemStatsQuery>(
    SYSTEM_STATS_QUERY,
    {
      ssr: false,
      pollInterval: season ? pollInterval : undefined
    }
  );

  // set animation duration to (almost) the same as pollInterval, to make an illusion that it's counting up.
  // animation duration is slightly less than pollInterval, to make sure it is finished before polling again.
  const animate = { duration: pollInterval * 0.9 };

  return (
    <>
      {season && (
        <StatsItem
          id="systemActiveTripCount"
          title={
            <FormattedMessage
              id="riding_right_now"
              defaultMessage="Riding right now"
            />
          }
          value={
            <LoadingOrErrorOrValue
              loading={loading}
              value={data?.systemActiveTripCount?.count || 0}
              error={error}
              animate={animate}
            />
          }
          first={Boolean(season)}
        />
      )}
      <StatsItem
        id="tripsToday"
        first={Boolean(!season)}
        title={
          <FormattedMessage id="trips_today" defaultMessage="Trips today" />
        }
        value={
          season ? (
            <LoadingOrErrorOrValue
              loading={loading}
              error={error}
              value={data?.systemStats?.tripsToday || 0}
              animate={animate}
            />
          ) : null
        }
        extra={
          <>
            {season && (
              <>
                <FormattedMessage
                  id="trips_yesterday"
                  defaultMessage="Trips yesterday: {value}"
                  values={{
                    value: (
                      <LoadingOrErrorOrValue
                        loading={loading}
                        error={error}
                        value={data?.systemStats?.tripsYesterday || 0}
                      />
                    )
                  }}
                />
                <br />
              </>
            )}
            <time
              title={intl.formatDate(
                data?.systemStats?.highscoreUniqueUsersDate
              )}
              dateTime={data?.systemStats?.highscoreUniqueUsersDate}
            >
              <FormattedMessage
                id="max_trips_in_one_day"
                defaultMessage="Most trips in one day: {value}"
                values={{
                  value: (
                    <LoadingOrErrorOrValue
                      loading={loading}
                      error={error}
                      value={data?.systemStats?.highscoreTrips || 0}
                    />
                  )
                }}
              />
            </time>
            {isSameDay(
              new Date(data?.systemStats?.highscoreUniqueUsersDate || ''),
              now
            ) && <span> 🎉</span>}
          </>
        }
      />
      <StatsItem
        id="uniqueUsersToday"
        title={
          <FormattedMessage
            id="cyclists_today"
            defaultMessage="Cyclists today"
          />
        }
        value={
          season ? (
            <LoadingOrErrorOrValue
              loading={loading}
              error={error}
              value={data?.systemStats?.uniqueUsersToday || 0}
              animate={animate}
            />
          ) : null
        }
        extra={
          <FormattedMessage
            id="max_unique_cyclists_in_one_day"
            defaultMessage="Most cyclists in one day: {value}"
            values={{
              value: (
                <LoadingOrErrorOrValue
                  loading={loading}
                  error={error}
                  value={data?.systemStats?.highscoreUniqueUsers || 0}
                />
              )
            }}
          />
        }
      />
      <>
        <StatsItem
          id="medianTripDuration"
          title={
            <FormattedMessage
              id="median_trip_duration"
              defaultMessage="Median trip duration"
            />
          }
          value={
            season ? (
              <LoadingOrErrorOrValue
                loading={loading}
                value={secondsToMMSS(
                  data?.systemStats?.medianDurationToday || -1
                )}
                error={error}
              />
            ) : null
          }
          extra={
            <>
              <FormattedMessage
                id="MedianDurationThisSeasonMessage.year"
                defaultMessage="Median trip duration in {startDate, date, year-only}: {median}"
                values={{
                  startDate: now,
                  median: secondsToMMSS(
                    data?.systemStats?.medianDurationThisYear || -1
                  )
                }}
              />
            </>
          }
        />
        <StatsItem
          id="tripsThisYear"
          title={
            <>
              <FormattedMessage id="total_trips" defaultMessage="Total trips" />{' '}
              <FormattedDate value={now} format="year-only" />
            </>
          }
          value={
            <LoadingOrErrorOrValue
              loading={loading}
              value={data?.systemStats?.tripsThisYear || 0}
              error={error}
              animate={season ? animate : undefined}
            />
          }
          extra={
            data?.systemStats?.tripsLastYear &&
            data.systemStats.tripsLastYear > 0 && (
              <>
                <FormattedMessage
                  id="total_trips"
                  defaultMessage="Total trips"
                />{' '}
                <FormattedDate value={subYears(now, 1)} format="year-only" />:{' '}
                <FormattedNumber value={data.systemStats.tripsLastYear} />
              </>
            )
          }
        />
      </>
    </>
  );
};
