import { defineMessages } from 'react-intl';

export const genders = defineMessages({
  male: {
    id: 'Profile.UserForm.genders.male',
    defaultMessage: 'Male'
  },
  female: {
    id: 'Profile.UserForm.genders.female',
    defaultMessage: 'Female'
  },
  other: {
    id: 'Profile.UserForm.genders.other',
    defaultMessage: 'Other'
  }
});

export const globalMessages = defineMessages({
  genericError: {
    id: 'error_generic',
    defaultMessage: 'Something went wrong. Please try again.'
  },
  logIn: {
    id: 'globalMessages.logIn',
    defaultMessage: 'Log in'
  },
  extendedRental: {
    id: 'globalMessages.extendedRental',
    defaultMessage: 'Extended rental'
  },
  save: {
    id: 'components.buttons-i18n.save',
    defaultMessage: 'Save',
    description: 'Generic save message'
  },
  addCard: {
    id: 'add_card',
    defaultMessage: 'Add card'
  },
  confirmCloseDialog: {
    id: 'confirm_close_dialog',
    defaultMessage: 'You have unsaved changes, do you want to close the dialog?'
  }
});
