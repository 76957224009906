import { useState, useEffect, useContext, useRef } from 'react';
import { ThemeContext } from 'styled-components';

export function useNoSSR() {
  const [noSSR, set] = useState(false);
  useEffect(() => {
    set(true);
  }, []);
  return noSSR;
}

export function useStyledTheme() {
  const theme = useContext(ThemeContext);
  return theme || {};
}

export const usePrevious = <T extends {}>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
