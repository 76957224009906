import { SystemConfig } from '../../../lib/types';

export const EBIKE_DOTS_LAYER_ID = 'ebike-dots';
export const EBIKE_NAMES_LAYER_ID = 'ebikes-names';
export const EBIKE_DOTS_SOURCE_ID = 'ebike-dots-source';

export function getEbikeDotsStyle(systemConfig: SystemConfig) {
  const ebikeDots: mapboxgl.AnyLayer = {
    id: EBIKE_DOTS_LAYER_ID,
    source: EBIKE_DOTS_SOURCE_ID,
    type: 'symbol',
    layout: {
      'icon-image': `${systemConfig.id}-bike`,
      'icon-allow-overlap': true,
      'icon-offset': [0, -6],
      'icon-size': {
        stops: [
          [8, 0.1],
          [10, 0.15],
          [12, 0.2],
          [16, 0.4],
          [18, 0.5]
        ]
      }
    }
  };
  return ebikeDots;
}

export const ebikeNames: mapboxgl.AnyLayer = {
  id: EBIKE_NAMES_LAYER_ID,
  type: 'symbol',
  source: EBIKE_DOTS_SOURCE_ID,
  layout: {
    'text-field': [
      'case',
      ['==', ['get', 'batteryCharge'], null],
      ['get', 'name'],
      ['==', ['get', 'currentRange'], null],
      ['concat', ['get', 'name'], '\n', ['get', 'batteryCharge'], '%'],
      ['<=', ['get', 'currentRange'], 1000],
      [
        'concat',
        ['get', 'name'],
        '\n',
        '<1 km',
        '\n',
        ['get', 'batteryCharge'],
        '%'
      ],
      ['>', ['get', 'currentRange'], 1000],
      [
        'concat',
        ['get', 'name'],
        '\n',
        '~',
        ['/', ['get', 'currentRange'], 1000],
        ' km',
        '\n',
        ['get', 'batteryCharge'],
        '%'
      ],
      //default
      ['get', 'name']
    ],
    'text-size': 10,
    'text-offset': [0, 3]
  },
  paint: {
    'text-color': '#000'
  }
};
