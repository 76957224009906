import { FC } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import Box from '../Box';

type RatioValue = {
  height: number;
  width: number;
};

type ResponsiveRatioValue =
  | {
      [str: string]: RatioValue;
    }
  | Array<RatioValue>;

type Props = {
  css?: string;
  borderRadius?: string;
  boxShadow?: string;
  children: React.ReactNode;
  ratio: ResponsiveRatioValue;
};

function calculateRatioPercent({ height, width }) {
  return `${(height / width) * 100}%`;
}

function calculatePaddingBottom(ratio) {
  if (Array.isArray(ratio)) {
    return ratio.map(calculateRatioPercent);
  }
  return Object.keys(ratio).reduce((accum, key) => {
    accum[key] = calculateRatioPercent(ratio[key]);
    return accum;
  }, {});
}

const Ratio = styled(Box).attrs<{ ratio: ResponsiveRatioValue }>(
  ({ ratio }) => {
    return {
      pb: calculatePaddingBottom(ratio)
    };
  }
)<{ ratio: ResponsiveRatioValue }>({ display: 'block', width: '100%' });

const FlexEmbedBox = styled(Box)({
  display: 'block',
  overflow: 'hidden',
  position: 'relative'
});

const FlexEmbedOverflow = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

// Stolen from https://github.com/suitcss/components-flex-embed
const FlexEmbed: FC<Props> = ({ children, ratio, ...props }) => {
  return (
    <FlexEmbedBox {...props}>
      <Ratio ratio={ratio} />
      <FlexEmbedOverflow>{children}</FlexEmbedOverflow>
    </FlexEmbedBox>
  );
};

export default FlexEmbed;
