import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  compose,
  display,
  DisplayProps,
  maxWidth,
  MaxWidthProps
} from 'styled-system';
import {
  Sync as SyncIcon,
  Play as PlayIcon
} from '@urbaninfrastructure/react-icons';
import { Flex, Box, IconButton } from '@urbaninfrastructure/react-ui-kit';
import FlexEmbed from '../FlexEmbed';
// import { useIntersection } from '../../lib/hooks'

export const StyledVideo = styled.video<MaxWidthProps & DisplayProps>`
  ${compose(maxWidth, display)};
  margin: 0 auto;
  transform-origin: top;
  transform: scale(1.8);
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    transform: scale(1);
  }
`;

StyledVideo.defaultProps = {
  display: 'block',
  maxWidth: ['100%']
};

const fadeIn = keyframes`
from { opacity: 0 }
`;

const PlayButtonWrapper = styled(Flex)`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    bottom: ${theme.space[3]}px;
    animation: ${fadeIn} 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  `}
`;

PlayButtonWrapper.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  mt: 'xs'
};

export const flexEmbedRatio = {
  _: { width: 1, height: 1 },
  lg: { width: 16, height: 9 }
};

type Props = React.DetailedHTMLProps<
  React.VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
> & {
  loop?: boolean;
  showPlayButton: boolean;
  // intersectionOptions: IntersectionObserverOptions
};

function Video({ autoPlay, showPlayButton, ...props }: Props) {
  const videoRef = React.useRef<HTMLVideoElement>();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isEnded, setIsEnded] = React.useState(false);
  // const [isIntersected, setIsIntersected] = React.useState(false)

  // const { observer, observerEntry, elRef } = useIntersection(
  //   intersectionOptions
  // )

  // if (observerEntry.isIntersecting && !isIntersected) {
  //   setIsIntersected(true)
  //   if (observer) {
  //     observer.disconnect()
  //   }
  // }

  // React.useEffect(
  //   () => {
  //     if (!videoRef.current) {
  //       return
  //     }
  //     if (isIntersected) {
  //       videoRef.current.play()
  //     }
  //   },
  //   [isIntersected]
  // )

  const Icon = isEnded || isPlaying ? SyncIcon : PlayIcon;

  return (
    <Box position="relative">
      <FlexEmbed ratio={flexEmbedRatio}>
        <StyledVideo
          // @ts-ignore
          ref={videoRef}
          muted
          // preload={isIntersected ? 'auto' : 'none'}
          // autoPlay={autoPlay && !isEnded}
          autoPlay={autoPlay}
          onPlay={() => {
            setIsPlaying(true);
          }}
          onEnded={() => {
            setIsPlaying(false);
            if (!isEnded) {
              setIsEnded(true);
            }
          }}
          {...props}
        />
      </FlexEmbed>
      {showPlayButton && !isPlaying && (
        <PlayButtonWrapper>
          <IconButton
            shape="cool"
            variant="inverted"
            small
            Icon={Icon}
            placement="left"
            onClick={() => {
              if (!videoRef.current) {
                return;
              }
              if (isPlaying) {
                videoRef.current.currentTime = 0;
              } else {
                videoRef.current.play();
              }
            }}
          >
            {isEnded || isPlaying ? (
              <FormattedMessage
                id="components.Video.playAgain"
                defaultMessage="Play again"
              />
            ) : (
              <FormattedMessage
                id="components.Video.play"
                defaultMessage="Play"
              />
            )}
          </IconButton>
        </PlayButtonWrapper>
      )}
    </Box>
  );
}

Video.defaultProps = {
  // intersectionOptions: {
  //   threshold: 0.75,
  //   rootMargin: '20px'
  // },
  autoPlay: true,
  playsInline: true,
  loop: false,
  showPlayButton: true
};

export default Video;
