import React, { FC } from 'react';
import { Box, useMapContext } from '../../index';
import { Text } from '../..';
import { FiltersListItem } from './FiltersListItem';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { useSettingsQuery } from '../../../lib/core/SettingsQuery';
import styled from 'styled-components';
import BatteryChargeFilter from './BatteryChargeFilter';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    justify-content: flex-start;
  }
`;

export const FiltersList: FC = () => {
  const { view } = useMapContext();

  //Quick fix. We will only show ebikes and virtual sations for milan-bikemi and verona systems'
  const { data: settingsData } = useSettingsQuery();
  const systemId = settingsData?.system?.id;

  switch (systemId) {
    case 'milan-bikemi':
    case 'verona-bike': {
      return (
        <>
          <StyledBox>
            {view === 'parking' && (
              <>
                <FiltersListItem
                  filter="virtual"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.virtualSingular} />
                    </Text>
                  }
                />
                <FiltersListItem
                  filter="physical"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.physicalSingular} />
                    </Text>
                  }
                />
              </>
            )}
            {view === 'bikes' && (
              <>
                <FiltersListItem
                  filter="bike"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.traditionalBikes} />
                    </Text>
                  }
                />
                <FiltersListItem
                  filter="ebike"
                  label={
                    <Text typoStyle="xxs" textTransform="capitalize">
                      <FormattedMessage
                        {...messages.eBikes}
                        values={{ count: 2 }}
                      />
                    </Text>
                  }
                />
                <FiltersListItem
                  filter="ebike_with_childseat"
                  label={
                    <Text typoStyle="xxs" textTransform="capitalize">
                      <FormattedMessage
                        {...messages.eBikesWithChildSeat}
                        values={{ count: 2 }}
                      />
                    </Text>
                  }
                />
              </>
            )}
          </StyledBox>
        </>
      );
    }
    case 'inurba-gdansk': {
      return (
        <>
          <StyledBox>
            {view === 'parking' && (
              <>
                <FiltersListItem
                  filter="virtual"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.virtualSingular} />
                    </Text>
                  }
                />
                <FiltersListItem
                  filter="physical"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.physicalSingular} />
                    </Text>
                  }
                />
              </>
            )}
            {view === 'bikes' && (
              <>
                <Box mb={4}>
                  <FiltersListItem
                    filter="bike"
                    label={
                      <Text typoStyle="xxs">
                        <FormattedMessage {...messages.bikes} />
                      </Text>
                    }
                  />
                  <FiltersListItem
                    filter="ebike"
                    label={
                      <Text typoStyle="xxs" textTransform="capitalize">
                        <FormattedMessage
                          {...messages.eBikes}
                          values={{ count: 2 }}
                        />
                      </Text>
                    }
                  />
                </Box>
                <BatteryChargeFilter />
              </>
            )}
          </StyledBox>
        </>
      );
    }
    default: {
      return (
        <>
          <StyledBox>
            {view === 'parking' && (
              <>
                <FiltersListItem
                  filter="physical"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.physicalSingular} />
                    </Text>
                  }
                />
              </>
            )}
            {view === 'bikes' && (
              <>
                <FiltersListItem
                  filter="bike"
                  label={
                    <Text typoStyle="xxs">
                      <FormattedMessage {...messages.bikes} />
                    </Text>
                  }
                />
              </>
            )}
          </StyledBox>
        </>
      );
    }
  }
};
