import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Feature } from 'geojson';
import { Parking as ParkingIcon } from '@urbaninfrastructure/react-icons';
import { Text, Flex, Box } from '../../';
import messages from '../messages';
import { DockGroupState } from '../../../core-types';
import { AvailableVehicleCategory } from './AvailableVehicleCategory';

export interface AvailabilityInfoProps {
  superpowers: boolean;
  dot: Feature;
}

export const AvailabilityInfo: FC<AvailabilityInfoProps> = props => {
  const { superpowers, dot } = props;

  const isNoAvailabilityInfo =
    !dot.properties?.availabilityInfo?.availableVehicles ||
    !dot.properties?.availabilityInfo?.availableDocks;
  if (isNoAvailabilityInfo) {
    return null;
  }

  const isHiddenForNoSuperPowers =
    !dot?.properties?.enabled &&
    dot?.properties?.state === DockGroupState.active &&
    !superpowers;
  if (isHiddenForNoSuperPowers) {
    return null;
  }

  const count = {
    bikes: dot?.properties?.availabilityInfo?.availableVehicles,
    parking: dot?.properties?.availabilityInfo?.availableDocks
  };
  const availableVehicleCategories =
    dot?.properties?.availabilityInfo?.availableVehicleCategories || [];

  return (
    <Flex mt={4} data-testid="AvailabilityInfo">
      {availableVehicleCategories.length > 0 && (
        <Box width={1}>
          {availableVehicleCategories.map(vehicleCategory => (
            <AvailableVehicleCategory
              key={vehicleCategory.category}
              vehicleCategory={vehicleCategory}
            />
          ))}
          <Flex alignItems="center">
            <ParkingIcon size="32px" color="primary" />
            <Text fontSize={1} ml="xxs">
              {count.parking}{' '}
              <FormattedMessage
                {...messages.parkingAvailability}
                values={{ plural_available: count.parking }}
              />
            </Text>
          </Flex>
        </Box>
      )}
      {availableVehicleCategories.length === 0 && (
        <>
          <Box width={1 / 2} textAlign="center">
            <Text fontSize={1}>
              {count.bikes} <br />
              <FormattedMessage
                {...messages.bikesAvailability}
                values={{ count: count.bikes }}
              />
            </Text>
          </Box>
          <Box width={1 / 2} textAlign="center">
            <Text fontSize={1}>
              {count.parking} <br />
              <FormattedMessage
                {...messages.parkingAvailability}
                values={{ plural_available: count.parking }}
              />
            </Text>
          </Box>
        </>
      )}
    </Flex>
  );
};
