import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIconCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={16}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="#fff" d="M18 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
    <path
      fill="#fff"
      d="M19.956 3H3.997C1.787 3 0 4.906 0 7v10c0 2.094 1.787 4 3.997 4h16.006C22.213 21 24 19.094 24 17V6.916C24 4.822 22.166 3 19.956 3ZM3.997 5h16.006c1.081 0 2.022.93 2.022 2v6H1.975V7c0-1.07.894-2 2.022-2Zm15.959 14.084H3.997c-1.081 0-2.022-1.06-2.022-2.129h20.003c0 1.07-.894 2.13-2.022 2.13Z"
    />
  </svg>
);
export default SvgIconCard;
