import React, { FC } from 'react';
import { CheckboxStateless } from '@urbaninfrastructure/react-ui-kit';
import { Box, useMapContext, DEFAULT_FILTERS } from '../../index';
import styled from 'styled-components';

export interface FiltersListItemProps {
  filter?: keyof typeof DEFAULT_FILTERS;
  label: React.ReactNode;
}

const StyledBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const FiltersListItem: FC<FiltersListItemProps> = props => {
  const { filter, label } = props;

  const { switchFilterValue, filters } = useMapContext();

  return (
    <StyledBox>
      <Box>
        <Box data-testid={filter} mb={3}>
          {filter && (
            <Box>
              <CheckboxStateless
                label={label}
                checked={filters?.[filter]}
                onChange={() => switchFilterValue && switchFilterValue(filter)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </StyledBox>
  );
};
