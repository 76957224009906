import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIconBlik = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.487 2.567a1.766 1.766 0 1 0-3.532-.024 1.766 1.766 0 0 0 3.532.024ZM8.871 1.355h1.973v11.208H8.871V1.355ZM12.598 5.223h1.973v7.34h-1.973v-7.34Z"
    />
    <path
      fill="#fff"
      d="M3.743 5.149c-.617-.001-1.226.152-1.77.445V1.355H0v7.54a3.75 3.75 0 0 0 2.31 3.462 3.74 3.74 0 0 0 5.104-2.73 3.75 3.75 0 0 0-1.591-3.846 3.74 3.74 0 0 0-2.08-.632Zm0 5.55a1.8 1.8 0 0 1-1.766-2.156A1.804 1.804 0 0 1 4.432 7.23a1.802 1.802 0 0 1 .976 2.356 1.803 1.803 0 0 1-1.665 1.113ZM21.061 12.564h2.542l-3.054-3.948 2.77-3.393h-2.306l-2.72 3.414v-7.28H16.32v11.207h1.974l-.002-3.918 2.77 3.918Z"
    />
  </svg>
);
export default SvgIconBlik;
