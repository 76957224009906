import { useState, useEffect } from 'react';
import { animate } from 'popmotion';
import { usePrevious } from '../../lib/hooks';
import { useReducedMotion } from 'framer-motion';

export function useAnimatedNumber(
  value: number,
  { duration = 1000 }: { duration?: number } | undefined = {}
): number {
  const reducedMotion = useReducedMotion();
  const [springValue, setSpringValue] = useState<number>(value);
  const previousValue = usePrevious(value);

  useEffect(() => {
    let mounted = true;
    if (reducedMotion || previousValue === undefined) {
      return;
    }
    animate({
      from: previousValue,
      to: value,
      duration,
      onUpdate: (value: number) => {
        if (mounted) {
          setSpringValue(value);
        }
      }
    });

    return () => {
      mounted = false;
    };
  }, [reducedMotion, value, setSpringValue]);

  if (reducedMotion) {
    return value;
  }

  return springValue;
}
