import { gql } from '@apollo/client';

export const SETTINGS_FRAGMENT = gql`
  fragment FooterNavColumn on SanityFooterNavColumn {
    _key
    title
    items {
      ...Navigation
    }
  }
  fragment Settings on SanitySettings {
    title
    primaryCta {
      current
    }
    privacyPageSlug {
      current
    }
    header {
      ctaButton {
        title
        page {
          _type
        }
        externalLink
        showIntercom
      }
    }
    footer {
      aboutOperator
      helpText
      navColumns {
        ...FooterNavColumn
      }
      socialMediaLinks {
        _key
        name
        url
      }
    }
    navigation {
      ...Navigation
    }
    cookieNotification
    strictlyNecessaryCookiesContent
    performanceCookiesContent
    functionalCookiesContent
    targetingCookiesContent
  }
`;
