import * as React from 'react';
import Link, { staticRouteMap } from './Link';

export type SanityLinkType = {
  page: { _id: string; _type: string; slug: { current: string } | null } | null;
  externalLink: string | null;
  showIntercom: boolean | null;
  title?: string | null;
  _key: string | null;
};

type RenderProps = {
  href?: string;
  onClick?: () => void;
};

export function isValidSanityLink(link: SanityLinkType): boolean {
  if (link.page || link.externalLink || link.showIntercom) {
    return true;
  }
  return false;
}

export default function SanityLink({
  link,
  children
}: {
  link: SanityLinkType;
  children: (renderProps: RenderProps) => React.ReactElement | null;
}) {
  if (!link) {
    return null;
  }
  const { page, externalLink, showIntercom } = link;

  const renderProps: RenderProps = {};

  let linkProps;

  if (page) {
    if (Object.keys(staticRouteMap).includes(page._type)) {
      linkProps = {
        route: page._type
      };
    } else if (page.slug && page.slug.current) {
      linkProps = {
        route: page._type,
        params: {
          slug: page.slug.current
        }
      };
    } else {
      return null;
    }
  } else if (externalLink) {
    renderProps.href = externalLink;
  } else if (showIntercom) {
    renderProps.onClick = () => {
      if (!window.Intercom) {
        return;
      }
      window.Intercom('show');
    };
  } else {
    return null;
  }

  if (linkProps) {
    return (
      <Link {...linkProps} passHref>
        {children(renderProps)}
      </Link>
    );
  }

  return children(renderProps);
}
