import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tariffs: {
    id: 'modules.BuyProduct.Products.tariffs',
    defaultMessage: 'Tariffs'
  },
  stepTwo: {
    id: 'modules.BuyProduct.Products.stepTwo',
    defaultMessage: 'Step 2: Pay at the end of your trip'
  }
});
