import { FC } from 'react';
import {
  Alert as UIKitAlert,
  AlertProps
} from '@urbaninfrastructure/react-ui-kit';

const Alert: FC<AlertProps> = props => {
  return <UIKitAlert mb={4} {...props} />;
};

export default Alert;
