import { FC } from 'react';
import { Flex, FlexProps } from '@urbaninfrastructure/react-ui-kit';

const ProductCard: FC<{ invert?: boolean } & Omit<
  FlexProps,
  'color' | 'bg'
>> = ({ invert, ...props }) => {
  return (
    <Flex
      bg={invert ? 'white' : 'primary'}
      color={invert ? 'text' : 'primaryContrast'}
      p={6}
      height="100%"
      borderRadius="lg"
      textAlign="center"
      flexDirection="column"
      {...props}
    />
  );
};

export default ProductCard;
