const MAP_URL = 'https://www.google.com/maps?saddr=My+Location&daddr=:lat,:lng';

export const getRouteUrl = (lat?: number, lng?: number): string => {
  if (!lat || !lng) return '';

  return MAP_URL.replace(':lat', lat.toString()).replace(
    ':lng',
    lng.toString()
  );
};
