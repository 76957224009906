export const ROOT_PARAM = '/:locale(nb|en|it|fr|de|pl|uk|sv|es|fi)?';

function getSlugParams(slug) {
  return Object.keys(slug)
    .map(key => slug[key])
    .join('|');
}

export function addRoutes(routes, config) {
  Object.keys(config).forEach(name => {
    const route = config[name];
    const page = route.page || name;
    const { slug, params = '' } = route;

    // start page
    if (!slug) {
      routes.add(name, `${ROOT_PARAM}${params}`, page);
      return;
    }

    const slugParams = getSlugParams(slug);

    const pattern = `${ROOT_PARAM}/:slug(${slugParams})${params}`;

    if (route.children) {
      Object.keys(route.children).forEach(nestedName => {
        const nestedRoute = route.children[nestedName];
        const nestedPage = nestedRoute.page || nestedName;
        const { slug, params = '' } = nestedRoute;

        const slugParams = getSlugParams(slug);

        routes.add(
          `${page}.${nestedName}`,
          `${pattern}/:slug2(${slugParams})${params}`,
          `${page}.${nestedPage}`
        );
      });
    }

    routes.add(name, pattern, page);
  });
}
