import * as React from 'react';
import { Flex, Text } from '@urbaninfrastructure/react-ui-kit';
import {
  isBikeMi,
  isVeronaBike,
  isInurbaRouen,
  isInurbaGdansk,
  isLondonGreenquarter
} from '../../lib/util';
import BikeMiLogo from './BikeMiLogo';
import VeronaBikeLogo from './VeronaBikeLogo';
import DefaultLogo from './DefaultLogo';
import InurbaRouenLogo from './InurbaRouenLogo';
import LondonGreenQuarterLogo from './LondonGreenquarterLogo';
import { useStyledTheme } from '../../lib/hooks';
import { useSystemConfig } from '../../lib/system-config-context';
import InurbaGdanskLogo from './InurbaGdanskLogo';

const Logo = ({
  title,
  color,
  ...props
}: {
  title?: string | null;
  color?: string;
} & React.SVGProps<SVGSVGElement>) => {
  const theme = useStyledTheme();
  const systemConfig = useSystemConfig();

  let logo:
    | 'bikeMi'
    | 'veronaBike'
    | 'inurba-rouen'
    | 'inurba-gdansk'
    | 'london-greenquarter'
    | 'default' = 'default';

  if (isBikeMi(theme)) {
    // bikemi logo already contains the title, so no need to repeat it
    logo = 'bikeMi';
  } else if (isVeronaBike(systemConfig)) {
    // verona bike logo already contains the title, so no need to repeat it
    logo = 'veronaBike';
  } else if (isInurbaRouen(systemConfig)) {
    // Rouen logo contains name of the system, so no need to repeat it
    logo = 'inurba-rouen';
  } else if (isInurbaGdansk(systemConfig)) {
    // Gdansk logo contains name of the system, so no need to repeat it
    logo = 'inurba-gdansk';
  } else if (isLondonGreenquarter(systemConfig)) {
    logo = 'london-greenquarter';
  }

  let systemLogo: React.ReactNode = null;

  switch (logo) {
    case 'bikeMi': {
      systemLogo = <BikeMiLogo width={props.width} height={props.height} />;
      break;
    }
    case 'veronaBike': {
      systemLogo = <VeronaBikeLogo width={props.width} height={props.height} />;
      break;
    }
    case 'inurba-rouen': {
      systemLogo = (
        <InurbaRouenLogo width={props.width} height={props.height} />
      );
      break;
    }
    case 'inurba-gdansk': {
      systemLogo = (
        <InurbaGdanskLogo width={props.width} height={props.height} />
      );
      break;
    }
    case 'london-greenquarter': {
      systemLogo = (
        <LondonGreenQuarterLogo width={props.width} height={props.height} />
      );
      break;
    }
    case 'default': {
      systemLogo = <DefaultLogo width={47} height={47} {...props} />;
      break;
    }
  }

  const showTitle =
    (title && logo === 'default') || logo === 'london-greenquarter';

  return (
    <Flex alignItems="center">
      <Text color={color}>{systemLogo}</Text>
      {showTitle && (
        <Text ml={3} typoStyle="xxs" color={color}>
          {title}
        </Text>
      )}
    </Flex>
  );
};

export default Logo;
