import styled from 'styled-components';
import {
  Facebook,
  Twitter,
  Youtube,
  Instagram
} from '@urbaninfrastructure/react-icons';
import { Anchor } from '@urbaninfrastructure/react-ui-kit';
import { SocialMediaName } from '../../core-types';

const StyledAnchor = styled(Anchor)`
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const icons = {
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  youtube: Youtube
};

export default function SocialIcon({
  name,
  size,
  color
}: {
  name: SocialMediaName;
  size?: string;
  color?: string;
}) {
  let Icon = icons[name];

  if (!Icon) {
    return null;
  }

  return <Icon size={size} color={color} />;
}

export const SocialIconAnchor = ({
  name,
  href,
  label,
  ...props
}: {
  name: SocialMediaName;
  href: string | null;
  label?: string;
  color?: string;
}) => {
  if (!href) {
    return null;
  }
  const formattedLabel = label || name;
  return (
    <StyledAnchor
      href={href}
      title={formattedLabel}
      aria-label={formattedLabel}
      {...props}
    >
      <SocialIcon size="24px" name={name} />
    </StyledAnchor>
  );
};
