import * as React from 'react';

const InurbaGdanskLogo = ({
  width = 320,
  height = 'auto',
  alt = 'Mevo'
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      width={width}
      height={height}
      src="/static/assets/inurba-gdansk-logo.svg"
      alt={alt}
    />
  );
};

export default InurbaGdanskLogo;
