import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { useMapContext } from '../MapContext';
import { FormattedMessage } from 'react-intl';
import { Text } from '../../../components';

const useStyles = makeStyles({
  root: {
    width: 300
  }
});

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 3,
    fontFamily: 'Urban Grotesk, Helvetica, sans-serif'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    },
    '&$disabled': {
      display: 'none'
    },
    '&.MuiSlider-thumb.Mui-disabled': {
      width: 16,
      height: 16,
      marginTop: -5,
      marginLeft: -12
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  mark: {
    height: 0,
    width: 0
  }
})(Slider);

const marks = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 100,
    label: '100%'
  }
];

function valuetext(value: number) {
  return `${value}%`;
}

export default function BatteryChargeFilter() {
  const classes = useStyles();

  const {
    handleBatteryChargeFilterChange,
    batteryChargeFilter,
    filters
  } = useMapContext();

  return (
    <div className={classes.root}>
      <Text>
        <FormattedMessage
          id="battery_level_content_description"
          defaultMessage="Battery level"
        />
      </Text>
      <PrettoSlider
        value={batteryChargeFilter}
        onChange={handleBatteryChargeFilterChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        defaultValue={[0, 100]}
        marks={marks}
        disabled={filters?.ebike === false}
      />
    </div>
  );
}
