// When adding new locales, remember to add it to lib/intl-relativetimeformat-polyfill.ts
export const locales = [
  'en',
  'nb',
  'it',
  'fr',
  'pl',
  'de',
  'uk',
  'sv',
  'es',
  'fi'
];
export const urbansharingAssetsPath = 'https://assets.urbansharing.design';
export const JWT_COOKIE_NAME = 'jwt_token';
export const GOOGLE_ANALYTICS_CLIENT_ID_COOKIE_NAME = '_ga';

export const iconSize = { _: '40px', md: '60px', lg: '80px' };

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidXJiYW5zaGFyaW5nIiwiYSI6ImNqbHc2YnFkbzB5ZmMzd3Axem9ja2doOHIifQ.Ml2393RAIwLoLpJHm0Cx4A';

export const videoUrls = {
  appFindParking: {
    sources: {
      default: {
        src: '/static/assets/videos/Finn_stativ_felix.mp4',
        poster: '/static/assets/videos/Finn_stativ_felix.jpg'
      }
    },
    bottomBorder: true
  },
  appFindUnlock: {
    sources: {
      oslobysykkel: {
        src: '/static/assets/videos/mobile_unlock_oslo.mp4',
        poster: '/static/assets/videos/mobile_unlock_oslo.jpg'
      },
      'bergen-city-bike': {
        src: '/static/assets/videos/mobile_unlock_bergen.mp4',
        poster: '/static/assets/videos/mobile_unlock_bergen.jpg'
      },
      trondheim: {
        src: '/static/assets/videos/mobile_unlock_trondheim.mp4',
        poster: '/static/assets/videos/mobile_unlock_trondheim.jpg'
      },
      'skrova-bike': {
        src: '/static/assets/videos/mobile_unlock_skrova.mp4',
        poster: '/static/assets/videos/mobile_unlock_skrova.jpg'
      },
      'inurba-rouen': {
        src: '/static/assets/videos/mobile_unlock_rouen.mp4',
        poster: '/static/assets/videos/mobile_unlock_rouen.png'
      },
      'inurba-gdansk': {
        src: '/static/assets/videos/mobile_unlock_gdansk.mp4',
        poster: '/static/assets/videos/mobile_unlock_gdansk.png'
      },
      'london-greenquarter': {
        src: '/static/assets/videos/mobile_unlock_london.mp4',
        poster: '/static/assets/videos/mobile_unlock_london.png'
      },
      default: {
        src: '/static/assets/videos/mobile_unlock_oslo.mp4',
        poster: '/static/assets/videos/mobile_unlock_oslo.jpg'
      }
    },
    bottomBorder: true
  },
  bikeParkingVirtual: {
    sources: {
      default: {
        src: '/static/assets/videos/Virtuel_docking_(Pause).mp4',
        poster: '/static/assets/videos/Virtuel_docking_(Pause).jpg'
      }
    },
    bottomBorder: false
  },
  bikeParkingPhysical: {
    sources: {
      default: {
        src: '/static/assets/videos/Docking_stativ(trille).mp4',
        poster: '/static/assets/videos/Docking_stativ(trille).jpg'
      }
    },
    bottomBorder: false
  }
};
