import styled from 'styled-components';
import Box from './Box';

const FormGroup = styled(Box)``;

FormGroup.defaultProps = {
  mb: 3
};

export default FormGroup;
