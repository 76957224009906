import * as React from 'react';
import Image from 'next/image';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Arrow,
  Box,
  Dialog,
  Flex,
  H2,
  Text,
  ButtonVariant,
  ButtonShape,
  AnchorButton,
  TextProps
} from '@urbaninfrastructure/react-ui-kit';
import { FormattedCurrency } from '../FormattedCurrency';
import { I18NBuy } from '../common-i18n';
import {
  ConnectionPriceMatrix,
  RentalMeterType,
  buyProductPageQuery_products_image
} from '../../core-types';
import { ShowTariffsMessage } from './ShowTariffsMessage';
import { ConnectionPriceMatrixes } from '../ConnectionPriceMatrixes';
import { messages } from '../../modules/BuyProduct/messages';
import ReactMarkdown from 'react-markdown';

export function ProductPrice({
  rentalMeterType,
  price,
  tripStartCharge,
  extendedRentalPeriodCharge,
  mb = 'xs',
  color = 'primaryText',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  as,
  typoStyle,
  ...props
}: TextProps & {
  rentalMeterType: RentalMeterType | null;
  price: number | null;
  tripStartCharge: number | null;
  extendedRentalPeriodCharge: number | null;
}): JSX.Element | null {
  const textProps = {
    ...props,
    mb
  };
  switch (rentalMeterType) {
    case 'running': {
      if (tripStartCharge === null || tripStartCharge === undefined) {
        // eslint-disable-next-line no-console
        console.error(
          'rentalMeterType running provided without tripStartCharge'
        );
        return null;
      }
      if (
        extendedRentalPeriodCharge === null ||
        extendedRentalPeriodCharge === undefined
      ) {
        // eslint-disable-next-line no-console
        console.error(
          'rentalMeterType running provided without extendedRentalPeriodCharge'
        );
        return null;
      }

      return (
        <>
          {price && price > 0 ? (
            <Text
              {...textProps}
              color={color as any}
              typoStyle={typoStyle || 'md'}
              mb={0}
            >
              <FormattedCurrency value={price} />
            </Text>
          ) : null}
          <Text
            color={color as any}
            typoStyle={typoStyle || 'xs'}
            {...textProps}
          >
            <FormattedMessage
              id="ProductPlanCard.rentalMeterType.running.tripStartCharge"
              defaultMessage="{tripStartCharge} to unlock + "
              values={{
                tripStartCharge: <FormattedCurrency value={tripStartCharge} />
              }}
            />
            <Text as="span" whiteSpace="nowrap">
              <FormattedMessage
                id="ProductPlanCard.rentalMeterType.running.periodCharge"
                defaultMessage="{periodCharge} per minute"
                values={{
                  periodCharge: (
                    <FormattedCurrency value={extendedRentalPeriodCharge} />
                  )
                }}
              />
            </Text>
          </Text>
        </>
      );
    }
    case 'standard':
    default: {
      if (price === null || price === undefined) {
        // eslint-disable-next-line no-console
        console.error('rentalMeterType standard provided without price');
        return null;
      }
      return (
        <Text
          as={'span' as any}
          typoStyle={typoStyle || 'md'}
          color={color as any}
          {...textProps}
        >
          <FormattedCurrency value={price} />
        </Text>
      );
    }
  }
}

export const RegistrationFee = ({ amount }: { amount: number }) => {
  return (
    <Text
      as={'span' as any}
      typoStyle="sm"
      mb="xs"
      color="primaryText"
      fontWeight="bold"
    >
      <FormattedCurrency value={amount} />
    </Text>
  );
};

export default function ProductPlanCard({
  name,
  description,
  rentalMeterType,
  price,
  tripStartCharge,
  extendedRentalPeriodCharge,
  image,
  renderBuyButton,
  connectionPriceMatrixes = []
}: {
  name: string;
  description: string | null;
  price: number | null;
  tripStartCharge: number | null;
  rentalMeterType: RentalMeterType | null;
  extendedRentalPeriodCharge: number | null;
  image: buyProductPageQuery_products_image | null;
  renderBuyButton?: (renderProps: {
    buttonProps: {
      variant: ButtonVariant;
      shape: ButtonShape;
      children: React.ReactNode;
    };
  }) => React.ReactNode;
  connectionPriceMatrixes?: ConnectionPriceMatrix[];
}): JSX.Element {
  const intl = useIntl();
  const hasTarriffs =
    connectionPriceMatrixes && connectionPriceMatrixes.length > 0;
  return (
    <>
      <Flex
        bg="white"
        boxShadow="heavy"
        height="100%"
        borderRadius="md"
        flexDirection="column"
      >
        {image &&
          image.url &&
          image.metadata &&
          image.metadata.dimensions &&
          image.metadata.dimensions.width &&
          image.metadata.dimensions.height && (
            <Box
              borderTopRightRadius="md"
              borderTopLeftRadius="md"
              style={{ overflow: 'hidden' }}
            >
              <Image
                src={image.url}
                alt={name || ''}
                width={image.metadata.dimensions.width}
                height={image.metadata.dimensions.height}
              />
            </Box>
          )}

        <Flex
          px={{ _: 4, sm: 6 }}
          pt="sm"
          pb="xs"
          flexDirection="column"
          justifyContent="flex-end"
          height="auto"
        >
          <H2 mb={0} typoStyle="md">
            {name}
          </H2>

          <ProductPrice
            rentalMeterType={rentalMeterType}
            price={price}
            tripStartCharge={tripStartCharge}
            extendedRentalPeriodCharge={extendedRentalPeriodCharge}
          />

          {description && (
            <Text as="p" mb="xs" whiteSpace="pre-line">
              <ReactMarkdown>{description}</ReactMarkdown>
            </Text>
          )}

          <Flex alignItems="center" flexWrap="wrap" mt={-3}>
            {renderBuyButton && (
              <Box mt={3} mr={3}>
                {renderBuyButton({
                  buttonProps: {
                    variant: 'primary',
                    shape: 'cool',
                    children: <I18NBuy />
                  }
                })}
              </Box>
            )}
            {hasTarriffs && (
              <Box mt={3}>
                <Dialog
                  heading={intl.formatMessage(messages.tariffs)}
                  renderOpenButton={openPortal => (
                    <AnchorButton
                      onClick={openPortal}
                      color="text"
                      textDecoration="none"
                      fontSize={1}
                    >
                      <Text as="span" mr={1}>
                        <ShowTariffsMessage />
                      </Text>
                      <Arrow color="primary" direction="right" />
                    </AnchorButton>
                  )}
                >
                  <ConnectionPriceMatrixes
                    items={connectionPriceMatrixes}
                    variant="simple"
                  />
                </Dialog>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
