import { FC } from 'react';
import * as React from 'react';
import { Box, Flex, Text, H2 } from '@urbaninfrastructure/react-ui-kit';

type Props = {
  heading: string;
  media: React.ReactNode;
};

const Feature: FC<Props> = ({ heading, children, media }) => {
  return (
    <Flex
      flexDirection={['column', 'row']}
      alignItems="center"
      justifyContent={['flex-start', 'space-between']}
      textAlign={['center', 'left']}
      mb="lg"
    >
      <Box width={[3 / 4, 1 / 3]} mb="md" order={[0, 1]}>
        {media}
      </Box>
      <Box width={[null, 1 / 2]}>
        <H2 typoStyle="md">{heading}</H2>
        <Text typoStyle="sm">{children}</Text>
      </Box>
    </Flex>
  );
};

export default Feature;
