import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import { ApolloError } from '@apollo/client';
import { WarningAlt } from '@urbaninfrastructure/react-icons';
import { Loading } from '../../index';
import { useAnimatedNumber } from '../../../lib/hooks/useAnimatedNumber';

interface AnimatedFormattedNumberProps {
  value: number;
  duration: number;
}

const AnimatedFormattedNumber: FC<AnimatedFormattedNumberProps> = props => {
  const { value, duration } = props;

  const animatedNumber = useAnimatedNumber(value, { duration });

  return <FormattedNumber value={animatedNumber} maximumFractionDigits={0} />;
};

export interface LoadingOrErrorOrValueProps {
  value: number | string | null;
  loading: boolean;
  error?: ApolloError;
  animate?: {
    duration: number;
  };
}

export const LoadingOrErrorOrValue: FC<LoadingOrErrorOrValueProps> = props => {
  const { value, loading, error, animate } = props;

  if (loading) {
    return <Loading bg="neutral.2" aria-label="loading" />;
  }
  if (error || value === null) {
    return <WarningAlt size={12} />;
  }
  if (typeof value === 'string') {
    return <>{value}</>;
  }
  return animate ? (
    <AnimatedFormattedNumber value={value} duration={animate.duration} />
  ) : (
    <FormattedNumber value={value} />
  );
};
